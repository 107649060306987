import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { CheckCircle, XCircle } from 'lucide-react';
import { useTheme } from './ThemeProvider';

const Progress = ({ value, className }) => {
    return (
        <div className={`w-full bg-gray-200 rounded-full h-2.5 ${className}`}>
            <div 
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                style={{ width: `${value}%` }}
            ></div>
        </div>
    );
};

const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

const QuestionnaireApp = () => {
    const { state } = useLocation();
    const questions = state?.questions || []; // Utiliser les questions générées ou un tableau vide
    const { theme } = useTheme();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [consecutiveCorrectAnswers, setConsecutiveCorrectAnswers] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [hintsUsed, setHintsUsed] = useState(new Array(questions.length).fill(false));
    const [grayedOutOptions, setGrayedOutOptions] = useState([]);
    const [timer, setTimer] = useState(30);

    // Utilisez useMemo pour éviter les dépendances changeantes
    const shuffledQuestions = useMemo(() => {
        return shuffleArray(questions);
    }, [questions]);

    useEffect(() => {
        setTimer(30);
    }, [currentQuestion]);

    const handleAnswer = useCallback((answerIndex) => {
        setSelectedAnswer(answerIndex);
        const isCorrect = answerIndex === questions[currentQuestion]?.correctAnswer;
        setConsecutiveCorrectAnswers(prev => isCorrect ? prev + 1 : Math.max(0, prev - 1));
        setGrayedOutOptions([]);
    }, [questions, currentQuestion]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (selectedAnswer !== null) {
                if (consecutiveCorrectAnswers < 20) {
                    setCurrentQuestion((prevQuestion) => (prevQuestion + 1) % questions.length);
                    setSelectedAnswer(null);
                    setGrayedOutOptions([]);
                } else {
                    setShowResult(true);
                }
            }
        }, 1500);
    
        return () => clearTimeout(timer);
    }, [selectedAnswer, consecutiveCorrectAnswers, questions.length, handleAnswer]);

    // Ajoutez un rendu conditionnel pour gérer le cas où il n'y a pas de questions
    if (questions.length === 0) {
        return <div>Aucune question disponible.</div>;
    }

    const handleHint = () => {
        // Logique pour gérer l'indice
        const updatedHintsUsed = [...hintsUsed];
        updatedHintsUsed[currentQuestion] = true;
        setHintsUsed(updatedHintsUsed);
    };

    const restartQuiz = () => {
        setCurrentQuestion(0);
        setConsecutiveCorrectAnswers(0);
        setShowResult(false);
        setSelectedAnswer(null);
        setHintsUsed(new Array(questions.length).fill(false));
    };

    return (
        <div className={`min-h-screen flex items-center justify-center ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
            <Card className={`w-full max-w-4xl mx-4 my-4 flex flex-col ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <CardHeader className="text-2xl sm:text-3xl font-bold text-center p-6">
                    <h1 className="font-semibold">Questionnaire sur l'offre d'emploi</h1>
                    <p className="text-lg">Temps restant : {timer} secondes</p>
                </CardHeader>
                <CardContent className="flex-grow flex flex-col justify-center p-4 sm:p-6">
                    {!showResult ? (
                        <>
                            <p className="mb-4 sm:mb-6 text-lg sm:text-xl md:text-2xl font-semibold">{shuffledQuestions[currentQuestion]?.question}</p>
                            <div className="space-y-4">
                                {shuffledQuestions[currentQuestion]?.options.map((option, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => handleAnswer(index)}
                                        className={`w-full justify-start text-left py-2 sm:py-4 px-4 sm:px-6 text-base sm:text-lg md:text-xl rounded-lg transition-all duration-300 ${
                                            selectedAnswer !== null
                                                ? index === shuffledQuestions[currentQuestion].correctAnswer
                                                    ? 'bg-green-500 text-white'
                                                    : index === selectedAnswer
                                                        ? 'bg-red-500 text-white'
                                                        : theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'
                                                : grayedOutOptions.includes(index)
                                                    ? 'bg-gray-400 text-gray-600 cursor-not-allowed'
                                                    : theme === 'dark' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                                        }`}
                                        disabled={selectedAnswer !== null || grayedOutOptions.includes(index)}
                                        tabIndex={0}
                                        aria-label={`Répondre avec l'option ${option}`}
                                    >
                                        <span className="flex-grow">{option}</span>
                                        {selectedAnswer !== null && index === shuffledQuestions[currentQuestion].correctAnswer && (
                                            <CheckCircle className="ml-2 flex-shrink-0" />
                                        )}
                                        {selectedAnswer === index && index !== shuffledQuestions[currentQuestion].correctAnswer && (
                                            <XCircle className="ml-2 flex-shrink-0" />
                                        )}
                                    </Button>
                                ))}
                            </div>
                            <Button
                                onClick={handleHint}
                                disabled={hintsUsed[currentQuestion] || selectedAnswer !== null}
                                className={`mt-4 ${
                                    hintsUsed[currentQuestion]
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-yellow-500 hover:bg-yellow-600'
                                } text-white`}
                            >
                                {hintsUsed[currentQuestion] ? 'Indice utilisé' : 'Utiliser un indice'}
                            </Button>
                        </>
                    ) : (
                        <div className="text-center">
                            <p className="text-2xl sm:text-3xl mb-6">Félicitations !</p>
                            <p className="text-3xl sm:text-4xl font-bold mb-6">
                                Vous avez réussi le questionnaire avec un score de {consecutiveCorrectAnswers.toFixed(1)} / 20 !
                            </p>
                            <Button onClick={restartQuiz} className="mt-6 text-xl sm:text-2xl py-3 px-6">
                                Recommencer le quiz
                            </Button>
                        </div>
                    )}
                </CardContent>
                {!showResult && (
                    <CardFooter className="flex-col space-y-4 p-6">
                        <div className="w-full flex justify-between items-center">
                            <p className="text-lg sm:text-xl">
                                Réponses correctes d'affilée : {consecutiveCorrectAnswers} / 20
                            </p>
                        </div>
                        <Progress value={(consecutiveCorrectAnswers / 20) * 100} className="w-full" />
                    </CardFooter>
                )}
            </Card>
        </div>
    );
};

export default QuestionnaireApp;