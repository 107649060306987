import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';
import axios from 'axios';
import { useTheme } from './ThemeProvider'; // Assurez-vous que cela est importé
import '../App.css'; // Assurez-vous que ce chemin est correct

const HomePage = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { theme } = useTheme(); // Récupérez le thème et la fonction de basculement
    const isProduction = process.env.NODE_ENV !== 'production'; // État de production
    const textareaRef = useRef(null); // Référence pour le textarea

    const handleSearch = async () => {
        setLoading(true);
        setError('');

        try {
            const endpoint = isProduction ? 'http://localhost:8080/api/generate' : 'http://localhost:8080/api/questions';
            const requestBody = { theme: searchTerm };

            const response = isProduction 
                ? await axios.post(endpoint, requestBody) // POST pour générer des questions
                : await axios.get(endpoint); // GET pour obtenir les questions par défaut

            const questions = isProduction ? response.data.questions : response.data; // Adapter selon la réponse

            navigate('/quiz', { state: { questions } });
        } catch (error) {
            console.error('Erreur lors de la récupération des questions :', error);
            setError('Une erreur est survenue lors de la récupération des questions. Veuillez réessayer.');
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour ajuster la hauteur du textarea
    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Réinitialiser la hauteur
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajuster à la hauteur du contenu
        }
    };

    useEffect(() => {
        adjustHeight(); // Ajuster la hauteur lors du chargement initial
    }, []);

    return (
        <div className={`flex flex-col items-center justify-center min-h-screen p-4 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
            <h1 className="text-3xl font-bold mb-2 text-center">Bienvenue sur Skill Insight</h1>
            <p className="text-lg mb-4 text-center">Un questionnaire fait sur mesure pour vous par notre IA !</p>
            <div className="flex flex-col w-full max-w-md mb-4">
                {!isProduction && (
                    <Button onClick={handleSearch} className="btn-custom" disabled={loading}>
                        Accéder au questionnaire
                    </Button>
                )}
                {isProduction && (
                    <>
                        <textarea
                            ref={textareaRef}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Entrez un thème ou un contexte"
                            className="flex-grow border border-gray-300 dark:border-gray-700 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 text-black mb-2 resize-none overflow-hidden"
                            rows={1}
                            maxLength={1500}
                        />
                        <Button onClick={handleSearch} className="btn-custom" disabled={loading || searchTerm.trim() === ''}>
                            Générer des questions
                        </Button>
                    </>
                )}
            </div>
            {error && <p className="text-red-500 text-center">{error}</p>}
        </div>
    );
};

export default HomePage;
