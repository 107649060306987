import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import QuestionnaireApp from './components/QuestionnaireApp';
import { ThemeProvider, useTheme } from './components/ThemeProvider';
import { Button } from './components/ui/button';
import { Moon, Sun } from 'lucide-react';
import SkillInsightLogo from './images/SkillInsightMovement.svg';

const ThemeToggleButton = () => {
    const { theme, toggleTheme } = useTheme();
    return (
        <div className="fixed top-4 right-4 z-10">
            <Button onClick={toggleTheme} variant="outline" size="icon" className="rounded-full">
                {theme === 'light' ? <Moon className="h-[1.2rem] w-[1.2rem]" /> : <Sun className="h-[1.2rem] w-[1.2rem]" />}
            </Button>
        </div>
    );
};

function App() {
    return (
        <ThemeProvider>
            <Router>
                <div className="App min-h-screen">
                    <div className="fixed top-4 left-4 z-10"> {/* Positionner le logo */}
                        <Link to="/"> {/* Enveloppez l'image dans un Link */}
                            <img src={SkillInsightLogo} alt="Skill Insight Logo" className="h-16" /> {/* Ajustez la taille selon vos besoins */}
                        </Link>
                    </div>
                    <ThemeToggleButton />
                    <Routes>
                        <Route path="/quiz" element={<QuestionnaireApp />} />
                        <Route path="/" element={<HomePage />} />
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;